<template>
	<div
		:id="tooltipToggleId"
		v-b-tooltip.hover.right.d800="tooltip ? tooltip : false"
		class="icon-toggle h-100 d-flex justify-content-center align-items-center pointer"
		@click="toggle"
		@mouseenter="hover = true"
		@mouseleave="hover = false">
		<i
			:class="{
				[activeIcon]: active,
				[inactiveIcon]: !active,
				[hoverClass]: hover,
				[leaveClass]: !hover,
			}" />
	</div>
</template>
<script>
export default {
	name: 'IconToggle',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		inactiveIcon: {
			type: String,
			default: '',
		},
		activeIcon: {
			type: String,
			default: '',
		},
		hoverClass: {
			type: String,
			default: '',
		},
		leaveClass: {
			type: String,
			default: '',
		},
		tooltip: {
			type: String,
			default: '',
		},
		toggleId: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			active: false,
			hover: false,
		};
	},
	computed: {
		tooltipToggleId() {
			return this.toggleId ? this.toggleId : 'icon-toggle';
		},
	},
	watch: {
		value: {
			handler(value) {
				this.active = value;
			},
			immediate: true,
		},
	},
	methods: {
		toggle() {
			this.active = !this.active;
			this.$root.$emit('bv::hide::tooltip', this.tooltipToggleId);
			this.$emit('update:value', this.active);
		},
	},
};
</script>
<style scoped>
.icon-toggle {
	width: 15px;
}
</style>
