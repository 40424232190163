const TEXT = 'text';
const PASSWORD = 'password';
const PREPEND_TEXT = 'prepend_text';
const PREPEND_PASSWORD = 'prepend_password';
const TEXTAREA = 'textarea';
const NUMBER = 'number';

const DEFAULT_INPUT_TYPE = TEXT;

export const INPUT_TYPES = {
	text: TEXT,
	password: PASSWORD,
	prependText: PREPEND_TEXT,
	prependPassword: PREPEND_PASSWORD,
	textarea: TEXTAREA,
	number: NUMBER,
	default: DEFAULT_INPUT_TYPE,
};

export const INPUT_TEXT_MAX_LENGTH = 190;

export default {};
