<template>
	<div
		:class="groupClass"
		class="input-group">
		<slot name="prepend" />
		<input
			:id="id"
			v-model="valueModel"
			:placeholder="placeholder"
			:class="{
				[inputClass]: !!inputClass,
				'form-control': !toggleClass,
				'rounded-0 border-top-0 border-left-0': onlyBottomBorder,
			}"
			:type="showPassword ? 'text' : 'password'"
			:name="name"
			:disabled="disabled"
			:maxlength="maxlength"
			:autocomplete="autocomplete"
			class="border-right-0">
		<div
			v-if="!disabled"
			class="input-group-append">
			<div
				:class="{
					[toggleClass]: !!toggleClass,
					'form-control': !toggleClass,
					'rounded-0 border-top-0 border-left-0 border-right-0': onlyBottomBorder,
				}"
				class="h-100 input-group-text bg-white">
				<icon-toggle
					:toggle-id="`${id}-password-toggle`"
					:tooltip="translate(showPassword ? 'hide' : 'show')"
					:value.sync="showPassword"
					active-icon="fas fa-eye-slash"
					inactive-icon="fas fa-eye"
					hover-class="text-muted" />
			</div>
		</div>
	</div>
</template>
<script>
import IconToggle from '@/components/IconToggle';
import { INPUT_TEXT_MAX_LENGTH } from '@/settings/Inputs';

export default {
	name: 'InputPassword',
	components: { IconToggle },
	props: {
		id: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		inputClass: {
			type: String,
			default: '',
		},
		groupClass: {
			type: String,
			default: '',
		},
		toggleClass: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		onlyBottomBorder: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		maxlength: {
			type: Number,
			default: INPUT_TEXT_MAX_LENGTH,
		},
		autocomplete: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			showPassword: false,
		};
	},
	computed: {
		valueModel: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit('input', newValue);
			},
		},
	},
};
</script>
<style scoped>
.is-invalid + .input-group-append > .input-group-text {
	border-color: #f86c6b;
}
</style>
